import { Carousel } from "@mantine/carousel";
import { ArticleCard } from "./ArticleCard";

const ArticleCarousel = ({ articles, title }) => {
  const carouselSlides = articles.map((article) => (
    <Carousel.Slide key={article.id}>
      <ArticleCard title={article.title} slug={article.slug} />
    </Carousel.Slide>
  ));

  return (
    <>
      {title && <h2 style={{ paddingLeft: "55x" }}>{title}</h2>}
      <Carousel
        height={130}
        slideSize="33.333333%"
        slideGap="md"
        align="start"
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
        ]}
      >
        {carouselSlides}
      </Carousel>
    </>
  );
};

export { ArticleCarousel };
